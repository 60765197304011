<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/forms/newsletter/form/groupFields'

const { $api, $httpCookies } = useNuxtApp()
const { t } = useI18n()
const { btnPrimary } = useButtons()
const { trackEvent } = useTracking()
btnPrimary.text = computed(() => t('popinNewsletter.iSubscribe'))
if (btnPrimary.attrs) {
  btnPrimary.attrs.blockFull = true
  ;(btnPrimary.attrs as any)['data-cy'] = 'submit-newsletter'
}

const maxAgeInSeconds = 3600 * 24 * 30
const groupFieldsForm = reactive(groupFields())
const initialData = reactive<{ email?: string }>({})
const showModal = ref(false)
const success = ref(false)
const timeMills = ref(15000)
const timeout = ref<NodeJS.Timeout>()

const submitNewsletter = (formData: { email: string }) =>
  $api.v1.newsletterEmails.create({ email: formData.email })
const mutation = (data: { email: string }) => ({
  request: submitNewsletter,
  input: {
    email: data.email,
  },
})
const onSubmit = () => ({
  success: () => {
    initialData.email = ''
    success.value = true
    trackEvent({
      event: 'newsletter_subscription',
      label: 'pop_in',
      post_alert: false,
    })
  },
  error: () => {
    success.value = false
  },
})

const startTimer = (millis: number) => {
  timeout.value = setTimeout(onExpires, millis)
  document.addEventListener('mousemove', onActivity)
  document.addEventListener('keydown', onActivity)
  document.addEventListener('touchstart', onActivity)
}
const onActivity = () => {
  if (timeout.value) clearTimeout(timeout.value)
  document.removeEventListener('mousemove', onActivity)
  document.removeEventListener('keydown', onActivity)
  document.removeEventListener('touchstart', onActivity)

  if (!showModal.value) {
    setTimeout(() => {
      startTimer(timeMills.value)
    }, 1000)
  }
}
const onExpires = () => {
  timeout.value = 0 as unknown as NodeJS.Timeout
  showModal.value = true
}
const closeModal = () => {
  showModal.value = false
  setLocalStorage()
}
const setLocalStorage = () =>
  $httpCookies?.set('close_popin_nl', true, {
    maxAge: maxAgeInSeconds,
  })

onMounted(() => {
  if (!$httpCookies?.get('close_popin_nl')) {
    startTimer(timeMills.value)
  }
})
</script>

<template>
  <LazyBaseModalV2
    v-model="showModal"
    size="s"
    data-cy="modal-newsletter"
    @close="setLocalStorage"
  >
    <template #content>
      <div class="w-full mb-6">
        <BaseNuxtImg
          width="420"
          height="275"
          src="production/assets/images/newsletter/pop_in_desktop.jpg"
          alt="img"
        />
      </div>

      <div v-if="success" class="text-center">
        <div class="font-monospace text-2xl font-bold mb-4">
          {{ $t('popinNewsletter.thankYou') }}
        </div>

        <p class="text-xs mb-6">
          {{ $t('popinNewsletter.text2') }}
        </p>

        <base-button
          class="text-md"
          color="black"
          variant="link"
          data-cy="close-modal"
          @click="closeModal"
        >
          {{ $t('global.close') }}
        </base-button>
      </div>
      <div v-else class="text-center">
        <div class="font-monospace text-2xl font-bold mb-4">
          {{ $t('popinNewsletter.title') }}
        </div>

        <p class="text-xs mb-6">
          {{ $t('popinNewsletter.text') }}
        </p>

        <base-form
          :active-modal-errors="false"
          :active-modal-save-or-quit="false"
          :button-primary="btnPrimary"
          :group-fields="groupFieldsForm"
          :initial-data="initialData"
          :mutation="mutation"
          :on-submit="onSubmit"
          container-button-class="mt-3 flex-wrap"
          id-form="popin-newsletter-form"
        />
      </div>
    </template>
  </LazyBaseModalV2>
</template>
