<script setup lang="ts">
const { trackEvent } = useTracking()
const localePath = useLocalePath()
const emits = defineEmits<{
  'close-modal': []
}>()

const props = defineProps<{
  isVallat: boolean
}>()

const showModal = ref(props.isVallat)

const closeModal = () => {
  showModal.value = false

  emits('close-modal')
}

onMounted(() => {
  if (showModal.value) {
    trackEvent({
      event: 'popin',
      category: 'redirection',
      label: 'vallat-lodges.com',
    })
  }
})
</script>

<template>
  <BaseModalV2
    :model-value="showModal"
    data-cy="modal-vallat"
    size="s"
    @close="closeModal"
  >
    <template #content>
      <div class="text-left">
        <div class="w-[245px]">
          <BaseNuxtImg
            src="/production/assets/images/vallat/vallat-lodges-logo.png"
            width="245"
            height="120"
            class="!mx-0 mt-8"
            alt="The French Alps chalets"
          />
        </div>

        <div class="font-monospace font-bold mt-10 mb-4 text-5xl leading-9">
          {{ $t('popinVallat.title') }}<br />
          {{ $t('popinVallat.brand') }}
        </div>

        <p class="text-base mb-6">
          {{ $t('popinVallat.text1') }}
          <base-button
            color="black"
            :to="localePath('vallat')"
            variant="link"
            @click="closeModal"
          >
            {{ $t('popinVallat.link') }}
          </base-button>
        </p>
        <p class="text-base mb-14">
          {{ $t('popinVallat.text2') }}
        </p>
        <div class="relative h-12">
          <base-button
            class="text-md absolute right-0 top-0"
            color="secondary"
            data-cy="close-modal"
            @click="closeModal"
          >
            {{ $t('popinVallat.close') }}
          </base-button>
        </div>
      </div>
    </template>
  </BaseModalV2>
</template>
